import { atom } from 'recoil'

export default atom({
  key: 'saveListing',
  default: {
    show: false,
    house: null,
    houseId: null,
    signupSource: null,
    saving: false,
    showFolder: false
  }
})
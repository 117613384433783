import { atom } from 'recoil'

export default atom({
  key: 'trackProperty',
  default: {
    show: false,
    house: null,
    trackedList: {},
    signupSource: null,
    tracking: false
  }
})
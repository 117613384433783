import { NProgress } from '@component/devsiteProgress'
import { useRouter } from 'next/navigation'
import { useCallback } from 'react'

/**
 * 带顶部进度条的路由跳转
 */
export default function useProgressRouter() {
  const router = useRouter()

  const pushRouter = useCallback((...args) => {
    NProgress.start()
    router.push(...args)
  }, [router])

  const replaceRouter = useCallback((...args) => {
    NProgress.start()
    router.replace(...args)
  }, [router])

  const backRouter = useCallback(() => {
    NProgress.start()
    router.back()
  }, [router])

  return {
    pushRouter,
    replaceRouter,
    backRouter
  }
}
'use client'

import { FORGOT, REGISTERED, SIGN_IN, SIGN_UP } from '@component/login/context'
import { PAGE_BREAKPOINT } from '@config'
import useConfirm from '@hooks/useConfirm'
import useUserState from '@hooks/useUserState'
import { global, login } from '@store/index'
import { callNative } from '@utils'
import { useSearchParams } from 'next/navigation'
import { useEffect } from 'react'
import { useWindowScroll, useWindowSize } from 'react-use'
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil'

export default function BaseInfo() {

  // is mobile
  const { width, height } = useWindowSize()
  const [globalState, setGlobalState] = useRecoilState(global)
  useEffect(() => {
    const isMobile = getIsMobile(width)
    if (globalState.mobile !== isMobile) setGlobalState(d => ({ ...d, mobile: isMobile }))
    if (globalState.isMobile !== isMobile) setGlobalState(d => ({ ...d, isMobile }))
  }, [globalState, width, setGlobalState])
  const { x, y } = useWindowScroll()
  useEffect(() => {
    if (typeof window === 'undefined') return
    document.documentElement.style.setProperty('--app-height', `${window.innerHeight}px`)
  }, [width, height, x, y])

  // signin signup forgot registered
  const searchParams = useSearchParams()
  const signin = searchParams.get(SIGN_IN)
  const signup = searchParams.get(SIGN_UP)
  const forgot = searchParams.get(FORGOT)
  const registered = searchParams.get(REGISTERED)
  const changeLoginState = useSetRecoilState(login)
  const { token } = useRecoilValue(login)
  useEffect(() => {
    changeLoginState(d => ({ ...d, [SIGN_IN]: !!signin, [SIGN_UP]: !!signup, [FORGOT]: !!forgot, [REGISTERED]: !!registered }))
  }, [signin, signup, forgot, registered, changeLoginState])

  // 缓存用户状态
  useUserState(token)

  // is from app
  useEffect(() => {
    const isFromApp = searchParams.get('from') === 'app'
    setGlobalState(d => ({ ...d, isFromApp }))
    if (isFromApp) {
      callNative('pageReady')
      document.addEventListener('click', event => {
        const parentA = event.target.closest('a')
        if (parentA) {
          event.preventDefault()
          event.stopPropagation()
          callNative('jump', { type: '', url: parentA.href, id: '' })
        }
      })
    }
  }, [searchParams, setGlobalState])

  const { openConfirm } = useConfirm()
  // 监听页面长时间不操作
  useEffect(() => {
    if (typeof window === 'undefined') return
    let timer = null
    const resetTimer = () => {
      // if (timer) clearTimeout(timer)
      // timer = setTimeout(async () => {
      //   await openConfirm({
      //     content: 'You have been inactive for a while, please refresh the page to continue using it.'
      //   })
      //   window.location.reload()
      // }, 1000 * 60 * 30)
    }
    resetTimer()

    window.addEventListener('mousemove', resetTimer)
    window.addEventListener('keydown', resetTimer)
    return () => {
      if (timer) clearTimeout(timer)
      window.removeEventListener('mousemove', resetTimer)
      window.removeEventListener('keydown', resetTimer)
    }
  }, [openConfirm])
}

export function getIsMobile(width) {
  // 判断userAgent
  const userAgent = typeof navigator === 'object' ? navigator.userAgent : ''
  if (userAgent.includes('Android') || userAgent.includes('iPhone')) {
    return true
  }
  return width <= PAGE_BREAKPOINT.sm
}
import httpClient from '@apiClient'
import { user } from '@store/index'
import { get, remove } from 'lodash-es'
import { useCallback, useEffect } from 'react'
import { useMount, useSessionStorage } from 'react-use'
import { useRecoilValue, useSetRecoilState } from 'recoil'

export default function useUserState(token) {

  const state = useRecoilValue(user)
  const setUserState = useSetRecoilState(user)
  const { updataNums } = state

  // 保存用户收藏房源id
  const setUserSavedHouseIds = useCallback((savedHouseIds) => {
    setUserState(d => ({ ...d, savedHouseIds }))
  }, [setUserState])
  // 加载用户收藏房源id
  const handleLoadUserState = useCallback(async () => {
    const data = await httpClient.get('user/state')
    if (data) {
      setUserSavedHouseIds(get(data, 'savedHouseIds', []))
    }
  }, [setUserSavedHouseIds])

  // 加载用户状态
  const [loadedState, setLoadedState] = useSessionStorage('loaded-state', false)
  useMount(() => {
    if (token && !loadedState) {
      setLoadedState(true)
      handleLoadUserState()
    }
  })

  // 获取用户通知数字
  useEffect(() => {
    if (!token) return
    httpClient.get('user/nums', { it: updataNums }).then(data => {
      setUserState(d => ({ ...d, nums: data }))
    })
  }, [updataNums, setUserState, token])

  // 更新用户通知数字
  const updateUserNum = useCallback(() => {
    localStorage.removeItem('user-num')
    setUserState(d => ({ ...d, updataNums: d.updataNums + 1 }))
  }, [setUserState])

  // 添加房源saved id
  const pushSavedHouseId = useCallback(houseId => {
    setUserState(state => {
      const { savedHouseIds = [] } = state
      return { ...state, savedHouseIds: [...savedHouseIds, houseId] }
    })
  }, [setUserState])

  // 删除房源saved id
  const removeSavedHouseId = useCallback(houseId => {

    setUserState(state => {
      const { savedHouseIds } = state
      const ids = [...savedHouseIds]
      remove(ids, id => id === houseId)
      return { ...state, savedHouseIds: ids }
    })
  }, [setUserState])

  // 清空缓存的saved数据
  const clearSavedHouseIds = useCallback(() => {
    setUserState(state => ({ ...state, savedHouseIds: [] }))
  }, [setUserState])

  return {
    ...state,
    pushSavedHouseId,
    removeSavedHouseId,
    clearSavedHouseIds,
    updateUserNum,
    handleLoadUserState
  }
}
import { refreshTokenUtil } from '@apiClient'
import { USER_INFO_KEY, USER_REFRESH_TOKEN_KEY, USER_TOKEN_KEY } from '@auth/config'
import { IS_SERVER } from '@config'
import { decodeToken } from '@plugin/http/lib/utils'
import { getRefreshToken, getToken, getUserInfo } from '@plugin/login'
import { object2camelCase } from '@utils'
import Cookies from 'js-cookie'
import { atom } from 'recoil'

export default atom({
  key: 'login',
  default: {
    info: null,
    token: null,
    refreshToken: null,
    signupSource: undefined,
    signin: false,
    signup: false,
    forgot: false,
    registered: false,

    // 没有密码提示
    noPassword: false,
    noPasswordUser: null,

    // 绑定Facebook
    bindFacebook: false,
    bindFacebookUser: null,

    // 登录/注册成功后回调
    doneHandle: null,
  },
  effects: [
    ({ setSelf, onSet }) => {
      if (IS_SERVER) return

      const token = getToken()
      const refreshToken = getRefreshToken()
      let info = getUserInfo()

      if (token && refreshToken && !info) {
        // 有token，但是没有info，有可能是从app过来的
        info = decodeToken(token)
        if (info) {
          info = object2camelCase(JSON.parse(info))
          info.name = [info.firstName, info.lastName].join(' ')
        }
      }

      if (token && refreshToken) {
        // 这里需要判断token是否过期，如果过期，则需要刷新token
        const { exp } = JSON.parse(decodeToken(token)) || {}
        const now = Math.floor(Date.now() / 1000)
        // 比较token是否过期，提前30分钟判断
        if (exp <= now + 60 * 30) {
          refreshTokenUtil.refresh().then(res => {
            const { token: newToken, refreshToken: newRefreshToken } = res || {}
            if (newToken && newRefreshToken) {
              const expires = new Date()
              expires.setFullYear(expires.getFullYear() + 1)
              Cookies.set(USER_TOKEN_KEY, newToken, { expires })
              Cookies.set(USER_REFRESH_TOKEN_KEY, newRefreshToken, { expires })
              setSelf(d => ({ ...d, info, token: newToken, refreshToken: newRefreshToken }))
            }
          })
        } else {
          setSelf(d => ({ ...d, info, token, refreshToken }))
        }
      }

      onSet(newValue => {
        if (newValue.info) {
          localStorage.setItem(USER_INFO_KEY, JSON.stringify(newValue.info))
        }
      })
    }
  ]
})

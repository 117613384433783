'use client'

import useRouterChange from '@hooks/useRouterChange'
import NProgress from 'nprogress'
import './index.scss'

// NProgress.configure({template: '<div class="bar" role="bar"><div class="peg"></div></div><div class="spinner" role="spinner"><div class="spinner-icon"></div></div>'})
NProgress.configure({ template: '<div class="bar" role="bar"><div class="peg"></div></div><div class="spinner" role="spinner"></div>' })

export {
  NProgress
}
export default function DevsiteProgress() {

  useRouterChange(routeChangeStartHandle, routeChangeCompleteHandle)

  function routeChangeStartHandle() {
    NProgress.start()
  }
  function routeChangeCompleteHandle() {
    NProgress.done()
  }
}

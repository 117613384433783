import { toNumber } from '@utils'
import { get, join, map, size, split } from 'lodash-es'
import { atom } from 'recoil'
import { getToken } from '@plugin/login'
import { IS_SERVER } from '@config'

const stateKey = 'user-state'
export default atom({
  key: 'user',
  default: {
    savedHouseIds: [],
    nums: null,
    updataNums: 0
  },
  effects: [
    ({ setSelf, onSet }) => {
      onSet(newValue => {
        // 前端用户操作时，将savedHouseIds保存到session中
        const savedHouseIds = join(get(newValue, 'savedHouseIds', []), ',')
        if (size(savedHouseIds)) {
          localStorage.setItem(stateKey, savedHouseIds)
        } else {
          localStorage.removeItem(stateKey)
        }
        // 保存nums到session中
        const nums = get(newValue, 'nums', null)
        if (nums) {
          localStorage.setItem('user-num', JSON.stringify(nums))
        } else {
          localStorage.removeItem('user-num')
        }
      })

      if (IS_SERVER) return
      // 只有处于登录状态才会去读取session中的saved数据
      if (getToken()) {
        // 读取session中的saved数据
        const savedHouseIds = map(split(localStorage.getItem(stateKey), ','), toNumber)
        // 读取session中的nums数据
        const nums = JSON.parse(localStorage.getItem('user-num'))
        setSelf(d => ({ ...d, savedHouseIds, nums }))
      }
    }
  ]
})
import { atom } from 'recoil'

export default atom({
  key: 'save-search',
  default: {
    show: false,
    data: null,
    state: null,
    suburbs: null,
    signupSource: null,
    successCallback: null
  }
})
import { getSlug } from '@utils'
import { get, map, size } from 'lodash-es'
import { atom } from 'recoil'

export default atom({
  key: 'gallery',
  default: {
    show: false,
    images: [],
    index: 0,
    floorPlanImages: [],
    imageTagTidy: null,
    address: '',
    house: null,
    currentTag: null,
    size: 0,
    showThreeD: false,
    showVideo: false,
    threeDUrl: null,
    videoUrl: null
  },
  effects: [
    ({ setSelf, onSet }) => {
      onSet(newValue => {
        setSelf(d => ({
          ...d,
          size: size(newValue.images) + size(newValue.floorPlanImages),
          imageTagTidy: map(newValue.imageTagTidy, item => ({ ...item, id: getSlug(item.label, size(item.images)) })),
          threeDUrl: get(newValue, 'house.threeDview', null),
          videoUrl: get(newValue, 'house.video', null)
        }))
      })
    }
  ]
})

import { atom } from 'recoil'

export default atom({
  key: 'trackSuburb',
  default: {
    show: false,
    suburb: null,
    signupSource: null,
    tracking: false
  }
})
// import { getIsMobile } from '@component/root'
import { getIsMobile } from '@component/frontendLayout/base'
import { IS_SERVER } from '@config'
import { atom } from 'recoil'

export default atom({
  key: 'global',
  default: {
    isMobile: IS_SERVER ? false : getIsMobile(window.document.body.clientWidth),
    mobile: false,
    // isFromApp: IS_SERVER ? false : window.location.search.includes('from=app'),
    isFromApp: false,
    theme: null
  }
})
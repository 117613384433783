import { useOpenSigninDialog, useOpenSignupDialog } from '@component/login/context'
import { getUserInfo, signin, signup, loginout, signinWithGoogle, signinWithFacebook, prospectUser, forgotUser, signupWithFacebook, bindWithFacebook, fetchUserInfo } from '@plugin/login'
import { login } from '@store/index'
import { assign } from 'lodash-es'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useBoolean } from 'react-use'
import { useRecoilValue, useSetRecoilState } from 'recoil'
import useUserState from './useUserState'
import { usePathname } from 'next/navigation'
import useProgressRouter from './useProgressRouter'

export default function useLogin() {

  const pathname = usePathname()
  const { replaceRouter } = useProgressRouter()

  const [user, setUser] = useState(null)
  const { info, token, signupSource, forceAddSignSource, doneHandle } = useRecoilValue(login)

  useEffect(() => {
    setUser(info)
  }, [setUser, info])

  const { clearSavedHouseIds } = useUserState()

  const [loaded, setLoaded] = useBoolean(false)
  const changeLoginState = useSetRecoilState(login)
  // 修改info, token, refreshToken
  const changeLoginStateData = useCallback((info, token, refreshToken) => {
    changeLoginState(d => assign({}, d, { info, token, refreshToken }))
  }, [changeLoginState])
  // 修改用户数据
  const changeInfo = useCallback(info => {
    changeLoginState(d => ({ ...d, info }))
  }, [changeLoginState])
  // 修改token
  const changeToken = useCallback(token => {
    changeLoginState(d => ({ ...d, token }))
  }, [changeLoginState])
  // 修改refreshToken
  const changeRefreshToken = useCallback(refreshToken => {
    changeLoginState(d => ({ ...d, refreshToken }))
  }, [changeLoginState])
  // 是否已经加载
  const changeLoaded = useCallback(loaded => {
    setLoaded(loaded)
  }, [setLoaded])

  // 是否登录状态
  const isLogin = useMemo(() => {
    return loaded && !!token
  }, [loaded, token])
  // 获取已登录用户
  useEffect(() => {
    if (isLogin) {
      const user = getUserInfo()
      if (user) changeInfo(user)
    }
    changeLoaded(true)
  }, [changeInfo, changeLoaded, isLogin])

  /**
   * 账号密码登录
   */
  const accountSignin = useCallback(async (data, remember) => {
    const res = await signin(data, signupSource, forceAddSignSource, remember)
    if (res.token) {
      await fetchUserInfo()
      changeLoginStateData(getUserInfo(), res.token, res.refreshToken)
    }
    return res
  }, [changeLoginStateData, signupSource, forceAddSignSource])

  /**
   * google登录
   */
  const googleSignin = useCallback(async (data, signupSource2) => {
    const res = await signinWithGoogle(data.credential, signupSource || signupSource2, forceAddSignSource)
    await fetchUserInfo()
    changeLoginStateData(getUserInfo(), res.token, res.refreshToken)
    return res
  }, [changeLoginStateData, signupSource, forceAddSignSource])

  /**
   * facebook登录
   */
  const facebookSignin = useCallback(async (data, remember) => {
    const res = await signinWithFacebook(data.userID, signupSource, forceAddSignSource, remember)
    if (res && res.token) {
      await fetchUserInfo()
      changeLoginStateData(getUserInfo(), res.token, res.refreshToken)
    }
    return res
  }, [changeLoginStateData, signupSource, forceAddSignSource])

  /**
   * 创建facebook账号
   */
  const createFacebook = useCallback(async data => {
    const res = await signupWithFacebook(assign({}, data, { signupSource }))
    if (res && res.token) {
      await fetchUserInfo()
      changeLoginStateData(getUserInfo(), res.token, res.refreshToken)
    }
    return res
  }, [changeLoginStateData, signupSource])

  /**
   * 已有账号绑定facebook
   */
  const bindFacebook = useCallback(async data => {
    const res = await bindWithFacebook(data)
    if (res && res.token) {
      await fetchUserInfo()
      changeLoginStateData(getUserInfo(), res.token, res.refreshToken)
    }
    return res
  }, [changeLoginStateData])

  /**
   * 账号密码注册
   */
  const accountSignup = useCallback(async (data, isCommercial, remember) => {
    const res = await signup(data, isCommercial, remember)
    await fetchUserInfo()
    changeLoginStateData(getUserInfo(), res.token, res.refreshToken)
    return res
  }, [changeLoginStateData])

  /**
   * 生成prospect user
   */
  const generateProspectUser = useCallback(async data => {
    return await prospectUser(assign({ signupSource }, data))
  }, [signupSource])

  /**
   * 找回密码
   */
  const forgotPassword = useCallback(async email => {
    return await forgotUser(email)
  }, [])

  /**
   * 退出登录清除数据
   */
  const handleSignout = useCallback(() => {
    loginout()
    changeInfo(null)
    changeToken(null)
    changeRefreshToken(null)
    clearSavedHouseIds()

    // 如果当前是个人中心页面则跳转到首页
    if (/^\/profile/i.test(pathname)) {
      replaceRouter('/')
    }
  }, [changeInfo, changeToken, changeRefreshToken, clearSavedHouseIds, pathname, replaceRouter])

  return {
    user,
    info,
    token,
    loaded,
    isLogin,
    signupSource,
    doneHandle,
    changeInfo,
    accountSignin,
    accountSignup,
    googleSignin,
    facebookSignin,
    createFacebook,
    bindFacebook,
    generateProspectUser,
    forgotPassword,
    handleSignout,
    changeToken,
    changeRefreshToken,
    changeLoginStateData
  }
}

/**
 * 验证是否已经登录，没有登录阻止跳转
 */
export function useSigninVerify(doneHandle, signupSource, forceAddSignSource = 0) {
  const { isLogin } = useLogin()
  const openSigninDialog = useOpenSigninDialog(signupSource, forceAddSignSource, doneHandle)

  return function loginVerify(event) {
    if (!isLogin) {
      event.preventDefault()
      openSigninDialog()
    }
  }
}

/**
 * 验证是否已经登录，没有登录阻止跳转
 */
export function useSignupVerify(doneHandle, signupSource, forceAddSignSource = 0) {
  const { isLogin } = useLogin()
  const openSignupDialog = useOpenSignupDialog(signupSource, forceAddSignSource, doneHandle)


  return function loginVerify(event) {
    if (!isLogin) {
      event.preventDefault()
      openSignupDialog()
    }
  }
}

'use client'

import { buildUrl } from '@plugin/http/lib/utils'
import { assign, omit, pick } from 'lodash-es'
import { usePathname, useRouter, useSearchParams } from 'next/navigation'
import { useSetRecoilState } from 'recoil'
import { login } from '@store/index'
import { createContext, useCallback, useContext } from 'react'
import useLogin from '@hooks/useLogin'
import { getToken } from '@plugin/login'

export const SIGN_IN = 'signin'
export const SIGN_UP = 'signup'
export const FORGOT = 'forgot'
export const REGISTERED = 'registered'
export const DIALOG_QUERY = [SIGN_IN, SIGN_UP, FORGOT, REGISTERED]

export const LoginContext = createContext()
export function useLoginContext() {
  return useContext(LoginContext)
}

/**
 * 关闭登录/注册窗口
 * @returns close dialog
 */
export function useCloseDialog() {

  const { router, path, query } = useDialogBase()
  const changeLoginState = useSetRecoilState(login)
  return function closeDialog() {
    changeLoginState(d => ({ ...d, signupSource: undefined, forceAddSignSource: 0, doneHandle: null, [SIGN_IN]: false, [SIGN_UP]: false, [FORGOT]: false, [REGISTERED]: false }))
    if (getToken() && query && query.redirect) {
      const pathName = decodeURIComponent(query.redirect)
      const url = buildUrl(pathName, undefined, omit(query, ['redirect']))
      window.history.replaceState(undefined, undefined, url)
      router.replace(url, { scroll: false })
    } else {
      const url = buildUrl(path, undefined, query)
      window.history.replaceState(undefined, undefined, url)
      router.replace(url, { scroll: false })
    }
  }
}

/**
 * 打开登录窗口
 * @returns open sign in
 */
export function useOpenSigninDialog(signupSource, forceAddSignSource = 0, doneHandle) {
  return useOpenBase(SIGN_IN, signupSource, forceAddSignSource, doneHandle)
}

/**
 * 打开注册窗口
 * @returns open sign in
 */
export function useOpenSignupDialog(signupSource, forceAddSignSource = 0, doneHandle) {
  return useOpenBase(SIGN_UP, signupSource, forceAddSignSource, doneHandle)
}

/**
 * 打开找回密码窗口
 * @returns open for got
 */
export function useOpenForgotDialog(signupSource, forceAddSignSource = 0) {
  return useOpenBase(FORGOT, signupSource, forceAddSignSource)
}

/**
 * 修改登录相关数据
 * @returns change login data
 */
export function useChangeLoginData() {
  const changeLoginState = useSetRecoilState(login)
  return data => {
    changeLoginState(d => (assign({ ...d }, pick(data, ['signupSource', 'forceAddSignSource']))))
  }
}

/**
 * 打开没有设置密码窗口
 * @returns open no password
 */
export function useNoPasswordDialog() {
  const changeLoginState = useSetRecoilState(login)
  return function openDialog(user) {
    changeLoginState(d => (assign({ ...d }, { noPasswordUser: user, noPassword: true })))
  }
}

/**
 * 打开绑定Facebook窗口
 * @returns open bind faceebook
 */
export function useBindFacebookDialog(signupSource) {
  const changeLoginState = useSetRecoilState(login)
  return function openDialog(user) {
    changeLoginState(d => (assign({ ...d }, { signupSource, bindFacebookUser: user, bindFacebook: true })))
  }
}

function useOpenBase(type, signupSource, forceAddSignSource = 0, doneHandle) {

  const { router, path, query } = useDialogBase()
  const changeLoginState = useSetRecoilState(login)
  const { info } = useLogin()
  const openDialog = useCallback(() => {
    if (info) return

    changeLoginState(d => (assign(
      { ...d, forceAddSignSource },
      signupSource ? { signupSource } : undefined,
      doneHandle ? { doneHandle } : undefined,
      { [SIGN_IN]: false, [SIGN_UP]: false, [FORGOT]: false, [REGISTERED]: false },
      { [type]: true }
    )))
    let q = assign({ [type]: 1 }, query)
    window.history.replaceState(undefined, undefined, buildUrl(path, undefined, q))

    // router.replace(buildUrl(path, undefined, q))
  }, [changeLoginState, info, path, query, signupSource, forceAddSignSource, doneHandle, type])

  return openDialog
}

function useDialogBase() {
  const router = useRouter()
  const path = usePathname()
  const searchParams = useSearchParams()

  const query = {}
  searchParams.forEach((val, key) => {
    if (DIALOG_QUERY.includes(key)) return
    query[key] = val
  })

  return {
    router, path, query
  }
}
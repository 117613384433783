import global from './modules/global'
import news from './modules/news'
import login from './modules/login'
import enquiry from './modules/enquiry'
import trackProperty from './modules/trackProperty'
import trackSuburb from './modules/trackSuburb'
import saveListing from './modules/saveListing'
import gallery from './modules/gallery'
import videoPlayer from './modules/videoPlayer'
import user from './modules/user'
import addOpenhome from './modules/addOpenhome'
import saveSearch from './modules/saveSearch'

export {
  global,
  news,
  login,
  enquiry,
  trackProperty,
  trackSuburb,
  saveListing,
  gallery,
  videoPlayer,
  user,
  addOpenhome,
  saveSearch,
}
import { isFunction } from 'lodash-es'
import { useCallback, useEffect, useRef } from 'react'

export default function useConfirm() {

  const rootRef = useRef(null)
  const wrapRef = useRef(null)

  useEffect(() => {
    if (wrapRef.current) return
    wrapRef.current = document.createElement('div')
  }, [wrapRef])


  // 打开confirm
  const openConfirm = useCallback((args, form) => {

    return new Promise(async (resolve, reject) => {
      const Confirm = (await import('@component/confirm')).default
      const createRoot = (await import('react-dom/client')).createRoot
      const root = createRoot(wrapRef.current)
      const closeHandle = () => {
        root.unmount()
      }
      root.render(<Confirm
        show
        onConfirm={() => resolve(true)}
        onCancel={() => resolve(false)}
        onClose={() => { resolve(false); closeHandle() }}
        {...args}
      >
        {
          isFunction(form)
            ? form({ closeHandle: res => { closeHandle(); resolve(res) } })
            : null
        }
      </Confirm>)
      rootRef.current = root
    })
  }, [wrapRef])

  // 关闭confirm
  const closeConfirm = useCallback(() => {
    if (rootRef.current) rootRef.current.unmount()
  }, [rootRef])

  return {
    openConfirm,
    closeConfirm
  }
}
import { atom } from 'recoil'

export default atom({
  key: 'add-openhome',
  default: {
    show: false,
    house: null,
    openhome: null,
    auction: null,
    openhomeLoading: {},
    openhomeSaved: {},
    auctionLoading: false,
    auctionSaved: false,
    online: false,
    signupSource: null,
  }
})
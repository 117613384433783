import { atom } from 'recoil'

export default atom({
  key: 'enquiry',
  default: {
    spec: null,
    show: false,
    success: false,
    formData: null,
    isHouse: false,
    isAgent: false,
    isSuburb: false,
    isOffice: false,
    isBuilder: false,
    isDisplayHome: false,
    // 是否发送给房源下所有中介 
    sendAllAgent: false,
    // 判断当前邮箱是否已注册
    registered: false,
    house: null,
    agent: null,
    suburb: null,
    office: null,
    // builder下面的enquiry brand/franchise/displayHome
    builder: null,
    brand: null,
    franchise: null,
    displayHome: null,
    signupSource: null
  }
})